import React from "react"
import "./Banner.scss"

const Banner = ({ title, description, image, alt, backgroundColor }) => {
  return (
    <section
      className="Section Banner component_wrapper_color"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="container">
        {title && (
          <div className="text">
            <h1>{title}</h1>
            {description && <p>{description}</p>}
          </div>
        )}
        <div className="image">
          <img src={image} alt={alt} />
        </div>
      </div>
    </section>
  )
}

export default Banner
